import * as React from 'react';
import {forwardRef} from 'react';
import * as InputMask from 'react-input-mask';
import {Input} from 'antd';

interface MaskedInputProps {
    mask: string,
    placeholder: string,
    name: string,
    prefix?: {type: string},
    className?: string
}

const MaskedInput: React.FC<MaskedInputProps> = forwardRef((props, ref) => {
    return (
        <InputMask {...props}>
            {inputProps => (
                <Input
                    {...inputProps}
                    ref={ref}
                />
            )}
        </InputMask>
    );
});

MaskedInput.defaultProps = {
    className: ""
};

export default MaskedInput;
