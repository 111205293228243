import React from 'react';
import {FC} from 'react';
import {Button, Drawer, Icon} from 'antd';
import {JSXElement} from "@babel/types";
import {IconComponent} from "antd/lib/icon";

interface IconButtonProp {
    className?: string,
    icon: string,
    type?: "ghost" | "link" | "default" | "primary" | "dashed" | "danger" | undefined,
    ghost?: boolean,
    htmlType?: "button" | "reset" | "submit" | undefined,
    onClick: () => void
}

const IconButton: FC<IconButtonProp> = ({icon, className, type, ghost, htmlType, onClick}) => {
    return (
        <Button type={type} className={className} ghost={ghost} htmlType={htmlType} onClick={onClick}>
            <Icon type={icon}/>
        </Button>
    )
};

IconButton.defaultProps = {
    type: "primary",
    ghost: false,
    className: "",
    htmlType: "button",
};

export default IconButton;
