import React from 'react';
import {FC} from 'react';
import {Layout, Icon, Input, Button, Typography, Form, message, notification} from 'antd';
import MaskedInput from '../_shared/MaskedInput';
import {FormComponentProps} from "antd/lib/form";
import {observer, inject} from "mobx-react";
import {profileStore} from "../../store/profileStore";

const {Title} = Typography;

interface LoginProps extends FormComponentProps {
    history: {
        push: (path: string) => void
    },
    profileStore: any
}

const Login: FC<LoginProps> = inject('profileStore')(({form, history, profileStore}) => {
    function onSubmit(event) {
        event.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {
                profileStore.fetchMenuData(values, 'usr.login').then(
                    () =>   history.push('/'),
                    e => {
                        console.log(e);
                        notification.open({
                            message: `${e}`,
                            icon: <Icon type={"frown"} />
                        });
                    }
                )
            }
        });
    }

    return (
        <Layout>
            <section className={"login"}>
                <div className="login__form">
                    <Title level={3}>
                        Scithis Projects
                    </Title>
                    <Form onSubmit={onSubmit}>
                        <Form.Item
                            hasFeedback
                            required
                        >
                            {
                                form.getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Поле не может быть пустым',
                                        },
                                    ],
                                })(
                                    <MaskedInput
                                        mask="+7(999) 999-99-99"
                                        prefix={<Icon type="phone" className={"login__icon"}/>}
                                        placeholder="Номер мобильного телефона"
                                        name={"name"}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            hasFeedback
                            required
                        >
                            {
                                form.getFieldDecorator('pass', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Поле не может быть пустым',
                                        },
                                    ],
                                })(
                                    <Input
                                        prefix={<Icon type="lock" className={"login__icon"}/>}
                                        type="password"
                                        placeholder="Пароль"
                                        name={"pass"}
                                    />
                                )
                            }
                        </Form.Item>
                        <Button type="primary" htmlType="submit" className="login__button">
                            Вход
                        </Button>
                    </Form>
                </div>
            </section>
        </Layout>
    )
});

export default Form.create()(Login);
