import * as React from "react";
import {Button, Col, DatePicker, Icon, Input, Modal, Row, Upload, Form, message} from "antd";
import {useState} from "react";
import Uploader from '../_shared/PhotoUploader';
import classNames from 'classnames';

const {TextArea} = Input;

const FormComp = ({form, horizontal = false}) => {

    function handleSubmit() {
        console.log(form.getFieldsValue())
    }

    const {getFieldDecorator} = form;

    return <Form className={classNames("card__inner form", horizontal ? "form_horizontal" : "")}>
        <h2>{
            getFieldDecorator(
                "protname"
            )(
                <Input className={"form__input"}/>
            )
        }</h2>
        <Row className={"grid__row card__wrapper card__wrapper_borderBottom"}>
            <div className={"grid__column grid__column_justify"}>
                <Form.Item className={"form__item"} label={'Дата релиза'}>
                    {
                        getFieldDecorator(
                            "date1", {}
                        )(
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'} placeholder={""}/>
                        )
                    }
                </Form.Item>
                <Form.Item className={"form__item"} label={'Дата завершения'}>
                    {
                        getFieldDecorator(
                            "date2", {}
                        )(
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'} placeholder={""}/>
                        )
                    }
                </Form.Item>
                <Form.Item className={"form__item"} label={'Препарат'}>
                    {
                        getFieldDecorator(
                            "name1", {}
                        )(
                            <Input className={"form__input"}/>
                        )
                    }
                </Form.Item>
                <Form.Item className={"form__item"} label={'Ссылка'}>
                    {
                        getFieldDecorator(
                            "name2", {}
                        )(
                            <Input className={"form__input"}/>
                        )
                    }
                </Form.Item>
            </div>
    {/*        <div className={"grid__column grid__column_justify"}>
                <Uploader/>
            </div>*/}
        </Row>
        <Row className={"card__wrapper card__wrapper_grow"}>
            {
                getFieldDecorator(
                    "name3"
                )(
                    <TextArea
                        className={"card__dsc"}
                    />
                )
            }
        </Row>
        <Form.Item className={"form__buttons"}>
            <Button className={"form__button"} type={"default"}>Отмена</Button>
            <Button className={"form__button"} htmlType={"submit"} type={"primary"}>Сохранить</Button>
        </Form.Item>
    </Form>
};

export default Form.create()(FormComp);
