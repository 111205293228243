import React, {Fragment, useState, FC} from 'react';
import {Layout, Menu, Icon, PageHeader, Row, Col, Avatar, Modal} from 'antd';
import {Link} from 'react-router-dom';
import ava from '../../images/logo_scithis.svg';
import classnames from 'classnames';
import IconButton from '../_shared/IconButton';
import {inject, observer} from "mobx-react";
import CONSTANTS from '../../config/constants';
import Form from '../form/Form';

const {Content, Sider} = Layout;

interface WrapperProps {
    className: string,
    profileStore: any
}

const Wrapper: FC<any> = inject('profileStore', 'homeStore')(observer(({children, className, profileStore, homeStore}) => {
    const {HOMEPAGE} = CONSTANTS;

    const {menu, token} = profileStore;
    const {data} = homeStore;

    const [isVisible, setOnClose] = useState(false);
    const [isModalOpen, toggleModal] = useState(false);

    function onClose() {
        setOnClose(!isVisible)
    }

    function onClick() {
        toggleModal(!isModalOpen)
    }

    return (
            <div className={classnames("layout", isVisible ? "layout_menuOpen" : "")}>
                <Layout className={"layout__wrapper"}>
                    {isVisible && <div className={"layout__cover"} onClick={onClose}/>}
                    <PageHeader className={"layout__header"} title={""}>
                        <Row>
                            <Col span={12} className={"layout__column layout__column_left"}>
                                <Link className={"layout__link"} to={"/home"}>
                                    <img src={ava} width={32} height={32} alt={"logo"}/>
                                    <h4 className={"layout__title"}>Scithis Projects</h4>
                                </Link>
                            </Col>
                            <Col span={12} className={"layout__column layout__column_right"}>
                                <IconButton
                                    icon={"plus"}
                                    className={"layout__button"}
                                    type={"primary"}
                                    onClick={onClick}
                                    ghost
                                />
                                <Avatar className={"layout__ava"}>U</Avatar>
                                {
                                    menu && menu.map(item => (
                                        <a className={"layout__logout"}
                                           href={`${HOMEPAGE}${item.script}?skey=${token}`}
                                           key={`${item.id}`}>
                                            <Icon type={item.label}/>
                                        </a>
                                    ))
                                }
                            </Col>
                        </Row>
                    </PageHeader>
                   <Layout>
                        <Content className={"layout__content"}>
                            <Row className={classnames("layout__inner", className)}>
                                {children}
                            </Row>
                        </Content>
                    </Layout>
                </Layout>
              <div
                    className={classnames("menu", isVisible ? "menu_open" : "")}
                >
                    <Sider
                        theme={'light'}
                        className={"menu__slider"}
                    >
                        <Menu theme="light" mode="inline" className={"menu__inner"} defaultSelectedKeys={['1']}>
                           {/* <Menu.Item key="1">
                                <Icon type="fire"/>
                                <span className="nav-text">nav 1</span>
                            </Menu.Item>*/}
                        </Menu>
                    </Sider>
                    <div className={"menu__handle"} onClick={onClose}>
                        <i className={"menu__handleIcon"}/>
                    </div>
                </div>
                <Modal visible={isModalOpen} onCancel={onClick} footer={false}>
                    <Form/>
                </Modal>
            </div>
            )
}));

Wrapper.defaultProps = {
    className: ""
};

export default Wrapper;
