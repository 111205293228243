interface FilterInterface {
    name: string,
    value: string
}

export function creatingFormData(
    action: string | null,
    object: string,
    data: any = {},
    filter: {name: string, value: string}[] = [],
    limit: number | null = null,
    offset: number | null = null,
    order: string | null = null,
)
{
    const skey = localStorage.getItem('token');
    const requestData = {
        ...{
            object,
        },
        ...action && {
            action
        },
        ...{
            filter,
            data,
            limit,
            offset,
            order
        },
        ...skey && {
            skey
        },
    };

    const func = action ? `${object}_${action}` : object;

    let formData = new FormData();
    formData.append('data', JSON.stringify(requestData));
    formData.append('function', func);
    return formData;
}
