import {observable, action} from "mobx";
import CONSTANTS from '../config/constants';
import {creatingFormData} from "../utils/creatingFormData";
import {notification} from "antd";

const {URL} = CONSTANTS;

class HomeStore {
    @observable data = [];

    @action setHomeData(data) {
        this.data = data;
    }

    fetchHomeData = (action = 'get', data={}) => {
        const requestData = creatingFormData(action, 'pro.projects', data, []);
        return fetch(URL, {
            method: 'POST',
            body: requestData
        }).then((response) => {
            if(response.status !== 200) {
                throw Error(response.statusText)
            }

            return response.json();
        }).then((data) => {
            if(data.status !== '0') {
                throw Error(data.msg);
            }

            this.setHomeData(data.data)
        })
    }
}

export default new HomeStore();
