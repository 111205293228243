import React, {Fragment, useState, useEffect} from 'react';
import {observer, inject} from "mobx-react";
import Wrapper from '../layout/Wrapper';
import Empty from '../empty/Empty';
import Form from '../form/Form';

const Home = inject('homeStore')(observer(({form, homeStore}) => {
    const [isModalVisible, toggleModalVisibility] = useState(false);
    const {fetchHomeData} = homeStore;

    useEffect(() => {
        fetchHomeData()
    }, []);

    function toggleVisibility() {
        toggleModalVisibility(!isModalVisible)
    }

    function handleSubmit(e) {
        e.preventDefault();
        console.log(form.getFieldsValue())
    }

    const {data} = homeStore;

    return (
        <Wrapper className={"card"}>
            {
                data.length === 0
                    ? <Empty/>
                    : <Form />
            }
        </Wrapper>
    )
}));

export default Home;
