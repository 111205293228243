import React from 'react';
import {FC, useEffect} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ProfileStoreInterface} from '../../store/profileStore';
import {observer, inject} from "mobx-react";
import COMPONENTS from '../../config/component';

interface RouterProps {
    profileStore?: ProfileStoreInterface
}

const Router: FC<any> = inject('profileStore')(observer(({profileStore}) => {
    const {fetchMenuData, routerList, defaultPage, defaultPath} = profileStore;

    useEffect(() => {
        fetchMenuData()
    }, []);

    return (
        <Switch>
            {
                routerList.map(item => <Route key={`${item.id}`}
                                              exact
                                              path={item.script}
                                              component={COMPONENTS[item.name]}/>)
            }
            <Redirect to={defaultPath} />
            <Route component={COMPONENTS[defaultPage]} />
        </Switch>
    )
}));

export default Router;
