import React from 'react';
import Router from '../router/Router';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "mobx-react";
import {profileStore} from '../../store/profileStore';
import homeStore from '../../store/homeStore';

function App() {
    return (
        <BrowserRouter basename={'/scithis'}>
            <Provider profileStore={profileStore} homeStore={homeStore}>
                <Router/>
            </Provider>
        </BrowserRouter>
    )
}

export default App;
