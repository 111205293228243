import {action, observable, computed} from "mobx";
import CONSTANTS from '../config/constants';
import {creatingFormData} from '../utils/creatingFormData';
import COMPONENTS from '../config/component';

const {HOMEPAGE} = CONSTANTS;

export  interface ProfileStoreInterface {
    token: string,
    menu: [],
    isLoading: boolean,
    fetchMenuData, routerList, defaultPage, defaultPath
}

class ProfileStore {
    @observable data = [];
    @observable isLoading = false;

    @action setMenuData(data) {
        this.data = data;
    }

    @computed get menu() {
        return this.data['menu'] ? this.data['menu'].filter(({menu}) => menu !== 0) : []
    }

    @computed get routerList() {
        return this.data['menu'] ? this.data['menu'].filter(({menu}) => menu === 0) : []
    }

    @computed get token() {
        return this.data['skey']
    }

    @computed get defaultPage() {
        return this.data['data'] && this.data['data'][0] ? this.data['data'][0].default_view_name : COMPONENTS['login']
    }

    @computed get defaultPath() {
        return this.data['data'] && this.data['data'][0] ? this.data['data'][0].default_view_path : '/'
    }

    fetchMenuData = (data = {}, object: string = 'usr.profile' ) => {
        const requestData = creatingFormData(null, object, data);
        this.isLoading = true;

        return fetch(HOMEPAGE + '/service/', {
            method: 'POST',
            body: requestData,
        }).then((response) => {
            if (response.status !== 200) {
                throw Error(response.statusText)
            }
            this.isLoading = true;
            return response.json();
        }).then((data) => {
            if (data.status !== '0') {
                throw Error(data.msg);
            }

            localStorage.setItem('token', data.skey);
            this.setMenuData(data);
        })
    }
}

export const profileStore = new ProfileStore();
